/* Floating button styles */
.floating-button {
  position: fixed;
  bottom: 8%;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  z-index: 1000;
  width: calc(100% - 40px); /* Set a consistent width across devices */
  max-width: 300px; /* Maximum width */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Sliding and fading animation */
}

/* Style the button as needed */
.floating-button button {
  background-color: #632CFF;
  color: white;
  border: none;
  border-radius: 50px; /* Make the border completely circular */
  cursor: pointer;
  font-size: 20px; /* Adjust the font size as needed */
  font-weight: bold;
  height: 50px;
  width: 100%;
}

.hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(20px); /* Slide down */
  pointer-events: none; /* Disable click events */
}

.animate-in {
  animation: slideUp 0.3s ease forwards;
}

@keyframes slideUp {
  from {
    transform: translateX(-50%) translateY(20px); /* Start below the final position */
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0); /* End at the final position */
    opacity: 1;
  }
}