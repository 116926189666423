.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: fadeIn 0.3s; */
}

.popup-content {
  background-color: #fff;
  padding: 30px 0 20px 0;
  border-radius: 20px;
  text-align: center;
  width: 70%; /* Adjust the width of the popup as needed */
  max-width: 360px; /* Adjust maximum width as needed */
  display: flex; /* Use flexbox to center children */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
}
/* Wrapper for all items inside the popup content for better control */
.popup-inner {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-logo {
  max-height: 50px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}
  
.popup-text, .popup-strong {
  color: black;
  margin: 0.75em 0 0.75em 0; /* Consistent margin for text elements */
}
  
.popup-button {
  padding: 10px 20px;
  background-color: #632CFF; /* Purple background */
  color: white; /* White text */
  border: none;
  border-radius: 25px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px; /* Adjust font size as needed */
  width: 70%; /* Adjust width as needed, or use width: 100% for full width */
  margin: 0.75em 0 1em 0; /* Consistent margin for buttons */
}
  
/*
@keyframes fadeIn {
  from { background-color: transparent; }
  to { background-color: rgba(0, 0, 0, 0.5); }
}
*/

/* 
@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
} */