.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background: linear-gradient(to bottom, #ededed 0%, rgb(47, 44, 37) 100%);
  /* This creates a gradient from green (#84fab0) to blue (#8fd3f4) */
  min-height: 100vh; /* Ensure it covers the whole page */
  margin: 0; /* Remove default margin */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 400px) {
  .contact-section h1 {
    font-size: 24px; /* Adjust the font size for smaller screens */
  }
  .contact-section h3 {
    font-size: 16px; /* Adjust the font size for smaller screens */
  }
}

@media (min-width: 401px) {
  .contact-section h1 {
    font-size: 30px; /* Adjust the font size for smaller screens */
  }
  .contact-section h3 {
    font-size: 20px; /* Adjust the font size for smaller screens */
  }
}

/* For mobile phones */
@media (max-width: 600px) {
  img {
    width: 100vw; /* 100% of viewport width */
    margin:0;
    display: block;
  }
  .contact-section {
    width: 100vw; /* 100% of viewport width */
    padding: 70px 20px 70px 20px; /* Add padding */
    box-sizing: border-box; /* Make sure padding doesn't affect width */
    background-color: #A381AF;
  }
  .footer {
    width: 100vw; /* 100% of viewport width */
    padding: 3rem 30px 4rem 30px; /* Add padding */
    box-sizing: border-box; /* Make sure padding doesn't affect width */
    background-color: #675E6B;
  }
  .contact-section .input-wrapper,
  .contact-section .checkbox-wrapper {
    width: 90%; /* Set the desired width here for mobile */
  }
}

/* For tablets and PCs */
@media (min-width: 601px) {
  .image-container {
    width: 601px; 
    margin: 0 auto; /* Center the image */
    display: flex; /* Use flex to center images vertically */
    align-items: center; /* Center images vertically */
    justify-content: center; /* Center images horizontally */
  }
  .contact-section {
    width: 601px; /* Match the width of the images above */
    margin: 0 auto; /* Center the section horizontally */
    padding: 70px 30px 70px 30px; /* Add padding */
    box-sizing: border-box; /* Make sure padding doesn't affect width */
    background-color: #A381AF;
  }
  img {
    display: block; /* Center the images horizontally */
    margin: 0; /* Override any default margins */
    width: 100%;
  }
  .footer {
    width: 601px; /* Match the width of the images above */
    margin: 0 auto; /* Center the section horizontally */
    padding: 3rem 30px 4rem 30px; /* Add padding */
    box-sizing: border-box; /* Make sure padding doesn't affect width */
    background-color: #675E6B;
  }
  .contact-section .input-wrapper,
  .contact-section .checkbox-wrapper {
    width: 80%; /* Set the desired width here for tablets and PCs */
  }
}

/* Add a wrapper for the input and button for better control */
.contact-section .title-wrapper {
  width: 95%; /* Set the desired width here */
  margin: 0 auto 30px; /* Center the wrapper and add margin below */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

.contact-section h1 {
  width: 100%;
  color: #fff; /* Set text color to white */
  text-align: center; /* Center the text */
}

.contact-section h3 {
  width: 100%;
  color: #fff; /* Set text color to white */
  text-align: center; /* Center the text */
  font-weight: normal;
}

.contact-section p {
  width: 100%;
  color: #fff; /* Set text color to white */
  text-align: left; /* Center the text */
}

/* Add a wrapper for the input and button for better control */
.contact-section .input-wrapper {
  margin: 0 auto 10px; /* Center the wrapper and add margin below */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

.contact-section input {
  width: 100%; /* Make input full width */
  padding: 15px 10px; /* Add padding */
  margin-bottom: 10px; /* Add margin below input */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Slightly round the corners */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  font-size: 16px;
}

.contact-section button {
  width: 100%; /* Make button full width */
  padding: 15px 10px; /* Add padding */
  background-color: #923DFF; /* Set button color to purple */
  color: #fff; /* Set text color to white */
  border: none; /* Remove border */
  border-radius: 5px; /* Slightly round the corners */
  cursor: pointer; /* Change cursor to pointer */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  font-size: 16px;
  font-weight: bold;
}

.contact-section .checkbox-wrapper {
  display: flex; /* Use flexbox for alignment */
  flex-direction: row; /* Stack children vertically */
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 10px;
}

.contact-section .checkbox-wrapper .privacy-link {
  color: white; /* Set text color to white */
  text-decoration: underline; /* Underline the text */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 12px;
}

.toast {
  display: flex;
  font-family: Noto Sans KR;
  font-size: 14px;
  gap: 10px;
  align-items: center;
}

.toast_button {
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #632CFF;
  color: #fff;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.footer .text-wrapper {
  width: 90%; /* Set the desired width here */
  margin: 0 auto; /* Center the wrapper and add margin below */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: start; /* Center children horizontally */
}

.footer .link-wrapper {
  width: 90%; /* Set the desired width here */
  margin: 0 auto; /* Center the wrapper and add margin below */
  display: flex; /* Use flexbox for alignment */
  flex-direction: row; /* Stack children vertically */
  justify-content: start; /* Center children horizontally */
}

.footer .text-wrapper p {
  text-align: left; /* Align text to the left */
  margin: 0.2rem; /* Remove default margin */
  color: #ACACAC;
  font-size: 12px;
}

.footer .link-wrapper a {
  text-align: left; /* Align text to the left */
  color: #ACACAC;
  text-decoration: underline;
  margin-right: 3em;
  font-size: 12px;
}
